/**
 * ================================================
 * Base Imports
 * ================================================
 */
import type { WretchError } from 'wretch/resolver'

/**
 * ================================================
 * Local Imports
 * ================================================
 */
import useAuthStore from '~/features/auth/store'
import { version } from '~/package.json'

export default function httpErrorHelper(error: WretchError) {
  // const router = useRouter()
  const authStore = useAuthStore()
  const error_code = random_string(12)

  const statusCode =
    error?.response?.status ||
    error?.response?.code ||
    error?.status ||
    'no status or Network Error'

  const API_URL = error?.response?.config?.url || error?.url || 'no url'

  const message = `
  ====================
  ERROR MESSAGE:
  ${
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.response?.data ||
    error?.response?.message ||
    error?.response?.error ||
    error?.message ||
    error?.cause ||
    JSON.stringify(error) ||
    'no message'
  }
  ====================
  User: ${authStore.user?.id ? authStore.user.email : 'no user'}
  Status: ${statusCode}
  Error Code: ${error_code}
  API URL: ${API_URL}
  Page: ${authStore.currentPage}
  Version: ${version}
  Time: ${new Date().toLocaleString()}
  ====================`

  console.log('🚀 ~', message)

  logtail({
    message: `API ERROR \n ${message}`,
    user: authStore.user?.id ? authStore.user.email : 'no user',
    statusCode: statusCode,
    appVersion: version,
    apiURL: API_URL
  })

  if (error?.response?.status === 401 || error?.status === 401) {
    notifier({
      //message: 'Auth failed. or your session has expired. Please log in again.',
      message: 'Please log in 😊',
      type: 'error'
    })

    return
  }

  if (error?.response?.status === 403 || error?.status === 403) {
    notifier({
      message: 'You are not authorized to perform this action',
      type: 'error'
    })

    return
  }

  if (error?.response?.status === 404 || error?.status === 404) {
    notifier({
      message: 'The requested resource was not found',
      type: 'error'
    })

    return
  }

  if (error?.response?.status === 422 || error?.status === 422) {
    notifier({
      message: 'There was an error processing your request',
      type: 'error'
    })

    return
  }

  if (error?.response?.status === 500 || error?.status === 500) {
    throw new Error(error.message)
  }

  return new Error(error.message)
}
