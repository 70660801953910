/**
 * ================================================
 * Warehouse Transfer API Factory
 * This factory contains all the methods for interacting with the warehouse transfer feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_URL = '/api/v2/content/landing-pages/pages'
const API_URL_LOCALES = '/api/v2/content/landing-pages/locales'

/**
 * ================================================
 * PAGES
 * ================================================
 */

/**
 * clearCache - Call
 */
export const clearCache = async (): Promise<void> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL}/clear-cache`)
    .get()
    .res()
    .catch((err) => err)

/**
 * clearCache - query
 */
const useClearCache = (options?: UseQueryOptions<void>) =>
  useQuery({
    queryKey: ['clearCache'],
    queryFn: clearCache,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * Get pages - Call
 */
export const getPages = async (locale = 'en'): Promise<T.getPagesResponse> =>
  await useWretch('FsyncAPI')
    .url(API_URL)
    .query({ locale })
    .get()
    .json<T.getPagesResponse>()
    .then((res) => res.sort((a, b) => (a.url > b.url ? 1 : -1)))
    .catch((err) => err)

/**
 * Get pages - query
 */
const useGetPages = (
  locale: ComputedRef<string>,
  options?: UseQueryOptions<T.getPagesResponse>
) =>
  useQuery({
    queryKey: ['pages', locale],
    queryFn: () => getPages(locale.value),
    ...options,
    refetchOnWindowFocus: false
  })

/**
 * Get page - Call
 */
export const getPage = async (
  context: QueryFunctionContext
): Promise<T.getPageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL}/${context.queryKey[1]}`)
    .query({ locale: context.queryKey[2] })
    .get()
    .json<T.getPageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get page - query
 */
const useGetPage = (payload: {
  /**
   * Page id (1, 2, 3, etc)
   */
  pageId: ComputedRef<number>
  /**
   * Page locale (en, es, fr, etc)
   */
  locale: ComputedRef<string>
  options?: UseQueryOptions<T.getPageResponse>
}) =>
  useQuery({
    queryKey: ['page', payload.pageId, payload.locale],
    queryFn: getPage,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...payload.options
  })

/**
 * update page - Call
 */
export const updatePage = async (
  payload: T.updatePageRequest
): Promise<T.updatePageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL}/${payload.id}`)
    .query({ locale: payload.locale })
    .json(payload)
    .put()
    .json<T.updatePageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * update page - mutation
 */

const useUpdatePage = (
  options?: UseMutationOptions<
    T.updatePageResponse,
    Error,
    T.updatePageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updatePage,
    ...options
  })

/**
 * ================================================
 * Sections
 * ================================================
 */
const SECTION_API_URL = '/api/v2/content/landing-pages/pages'

/**
 * Get section types - Call
 */
export const getSectionTypes = async (
  context: QueryFunctionContext
): Promise<T.getSectionTypesResponse> =>
  await useWretch('FsyncAPI')
    .query({ locale: context.queryKey[1] })
    .url(`${SECTION_API_URL}/section-types`)
    .get()
    .json<T.getSectionTypesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get section types - query
 */
const useGetSectionTypes = (payload: {
  locale: ComputedRef<string>
  options?: UseQueryOptions<T.getSectionTypesResponse>
}) =>
  useQuery({
    queryKey: ['sectionTypes', payload.locale],
    queryFn: getSectionTypes,
    ...payload.options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * update section - Call
 */
export const updateSection = async (
  payload: T.updateSectionRequest
): Promise<T.updateSectionResponse> =>
  await useWretch('FsyncAPI')
    .url(`${SECTION_API_URL}/${payload.pageId}/sections/${payload.sectionId}`)
    .query({ locale: payload.locale })
    .json({
      background_color: payload.background_color,
      text_body: payload.text_body,
      text_header: payload.text_header,
      section_type_id: payload.landing_pages_section_type_id,
      start_date: payload.start_date,
      end_date: payload.end_date,
      region: payload.region,
      active: payload.active,
      custom_classes: payload.custom_classes
    })
    .put()
    .json<T.updateSectionResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * update section - mutation
 */

//const queryClient = useQueryClient()

const useUpdateSection = (
  options?: UseMutationOptions<
    T.updateSectionResponse,
    Error,
    T.updateSectionRequest,
    unknown
  >
) =>
  useMutation({
    //mutationFn: updateSection,
    mutationFn: async (
      payload: T.updateSectionRequest
    ): Promise<T.updateSectionResponse> => {
      const response = await useWretch('FsyncAPI')
        .url(
          `${SECTION_API_URL}/${payload.pageId}/sections/${payload.sectionId}`
        )
        .query({ locale: payload.locale })
        .json({
          background_color: payload.background_color,
          text_body: payload.text_body,
          text_header: payload.text_header,
          section_type_id: payload.landing_pages_section_type_id,
          start_date: payload.start_date,
          end_date: payload.end_date,
          region: payload.region,
          active: payload.active,
          custom_classes: payload.custom_classes
        })
        .put()
        .res()

      if (!response.ok) {
        throw new Error(`${response}`) // throw error for correct error propagation
      }

      return response
    },
    ...options
  })

/**
 * ================================================
 * SLIDERS
 * ================================================
 */
const API_SLIDERS_CATEGORIES_URL = '/api/v2/content/sliders/categories'
const API_URL_SLIDERS = '/api/v2/content/landing-pages/resources/sliders'

/**
 * getSliderCategories - Call
 */
export const getSliderCategories =
  async (): Promise<T.sliderCategoriesResponse> =>
    await useWretch('FsyncAPI')
      .url(API_SLIDERS_CATEGORIES_URL)
      .get()
      .json<T.sliderCategoriesResponse>()
      .then((res) => res)
      .catch((err) => err)

/**
 * getSliderCategories - query
 */
const useGetSliderCategories = (
  options?: UseQueryOptions<T.sliderCategoriesResponse>
) =>
  useQuery({
    queryKey: ['sliderCategories'],
    queryFn: getSliderCategories,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * save slider - Call
 */
export const saveSlider = async (
  payload: T.storeSliderRequest
): Promise<T.storeSliderResponse> =>
  await useWretch('FsyncAPI')
    .url(API_URL_SLIDERS)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      slider_category_id: payload.sliderCategoryId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId
    })
    .post()
    .json<T.storeSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * save slider - mutation
 */
const useSaveSlider = (
  options?: UseMutationOptions<
    T.storeSliderResponse,
    Error,
    T.storeSliderRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: saveSlider,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store slider',
        type: 'error'
      })
  })

/**
 * Get slider - Call
 */
export const getSlider = async (
  payload: T.getSliderRequest
): Promise<T.getSliderResponse> =>
  await useWretch('FsyncAPI')
    .get(`${API_URL_SLIDERS}/${payload.sliderId}`)
    .json<T.getSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get slider - query
 */
const useGetSlider = (
  payload: T.getSliderRequest,
  options?: UseQueryOptions<T.getSliderResponse>
) =>
  useQuery({
    queryKey: ['slider', payload.sliderId],
    queryFn: () => getSlider({ sliderId: payload.sliderId }),
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * Update slider - Call
 */
export const updateSlider = async (
  payload: T.updateSlidersRequest
): Promise<T.updateSlidersResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL_SLIDERS}/${payload.sliderCategoryId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      slider_category_id: payload.sliderCategoryId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId
    })
    .put()
    .json<T.updateSlidersResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update slider - mutation
 */
const useUpdateSlider = (
  options?: UseMutationOptions<
    T.updateSlidersResponse,
    Error,
    T.updateSlidersRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateSlider,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update slider',
        type: 'error'
      })
  })

/**
 * Delete slider - Call
 */
export const deleteSlider = async (
  payload: T.deleteSliderRequest
): Promise<T.deleteSliderResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_URL_SLIDERS}/${payload.sliderId}`)
    .json({
      slider_id: payload.sliderId,
      section_block_id: payload.sectionBlockId
    })
    .delete()
    .json<T.deleteSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete slider - mutation
 */
const useDeleteSlider = (
  options?: UseMutationOptions<
    T.deleteSliderResponse,
    Error,
    T.deleteSliderRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: deleteSlider,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to delete slider',
        type: 'error'
      })
  })

/**
 * ================================================
 * PRODUCTS
 * ================================================
 */
const PRODUCTS_API_URL = '/api/v2/content/landing-pages/resources/products'

/**
 * save products - Call
 */
export const saveProducts = async (
  payload: T.saveProductsRequest
): Promise<T.saveProductsResponse> =>
  await useWretch('FsyncAPI')
    .url(PRODUCTS_API_URL)
    .query({ locale: payload.locale })
    .json({
      products: payload.products,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      use_slider: payload.use_slider
    })
    .post()
    .json<T.storeSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * save products - mutation
 */
const useSaveProducts = (
  options?: UseMutationOptions<
    T.saveProductsResponse,
    Error,
    T.saveProductsRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: saveProducts,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store product',
        type: 'error'
      })
  })

/**
 * Update products - Call
 */
export const updateProducts = async (
  payload: T.updateProductsRequest
): Promise<T.updateProductsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${PRODUCTS_API_URL}/${payload.blockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      products: payload.products,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      use_slider: payload.use_slider
    })
    .put()
    .json<T.updateProductsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update products - mutation
 */
const useUpdateProducts = (
  options?: UseMutationOptions<
    T.updateProductsResponse,
    Error,
    T.updateProductsRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateProducts,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update product',
        type: 'error'
      })
  })

/**
 * ================================================
 * Images
 * ================================================
 */

const IMAGE_API_URL = '/api/v2/content/landing-pages/resources/images'

/**
 * Get image - Call
 */
export const getImage = async (
  context: QueryFunctionContext
): Promise<T.getImageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${IMAGE_API_URL}/${context.queryKey[1]}`)
    .get()
    .json<T.getImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get image - query
 */
const useGetImage = (
  payload: T.getImageRequest,
  options?: UseQueryOptions<T.getImageResponse>
) =>
  useQuery({
    queryKey: ['image', payload.imageId],
    queryFn: getImage,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * store image - Call
 */
export const storeImage = async (
  payload: T.storeImageRequest
): Promise<T.storeImageResponse> =>
  await useWretch('FsyncAPI')
    .url(IMAGE_API_URL)
    .query({ locale: payload.locale })
    .json({
      ...payload.image,
      image_type_id: payload.imageTypeId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId
    })
    .post()
    .json<T.storeImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * store image - mutation
 */
const useStoreImage = (
  options?: UseMutationOptions<
    T.storeImageResponse,
    Error,
    T.storeImageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: storeImage,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store image',
        type: 'error'
      })
  })

/**
 * update image - Call
 */
export const updateImage = async (
  payload: T.updateImageRequest
): Promise<T.updateImageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${IMAGE_API_URL}/${payload.blockId}`)
    .query({ locale: payload.locale })
    .json({
      ...payload.image,
      block_id: payload.blockId,
      image_type_id: payload.imageTypeId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId
    })
    .put()
    .json<T.updateImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * update image - mutation
 */
const useUpdateImage = (
  options?: UseMutationOptions<
    T.updateImageResponse,
    Error,
    T.updateImageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateImage,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update image',
        type: 'error'
      })
  })

/**
 * delete image - Call
 */
export const deleteImage = async (
  payload: T.deleteImageRequest
): Promise<T.deleteImageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${IMAGE_API_URL}/${payload.imageId}`)
    .json({
      section_block_id: payload.sectionBlockId,
      image_id: payload.imageId
    })
    .delete()
    .json<T.deleteImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * delete image - mutation
 */
const useDeleteImage = (
  options?: UseMutationOptions<
    T.deleteImageResponse,
    Error,
    T.deleteImageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: deleteImage,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to delete image',
        type: 'error'
      })
  })

/**
 * ================================================
 * Grid images
 * ================================================
 */
const GRID_IMAGES_API_URL =
  '/api/v2/content/landing-pages/resources/images-grid'

/**
 * Update grid text - Call
 */
export const updateGridText = async (
  payload: T.updateGridTextRequest
): Promise<T.updateGridTextResponse> =>
  await useWretch('FsyncAPI')
    .url(`${GRID_IMAGES_API_URL}/${payload.sectionBlockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      ...payload.text
    })
    .put()
    .json<T.updateGridTextResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update grid text - mutation
 */
const useUpdateGridText = (
  options?: UseMutationOptions<
    T.updateGridTextResponse,
    Error,
    T.updateGridTextRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateGridText,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update grid text',
        type: 'error'
      })
  })

/**
 * Update grid image - Call
 */
export const updateGridImage = async (
  payload: T.updateGridImageRequest
): Promise<T.updateGridImageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${GRID_IMAGES_API_URL}/${payload.sectionBlockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      images: payload.images
    })
    .put()
    .json<T.updateGridImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update grid image - mutation
 */
const useUpdateGridImage = (
  options?: UseMutationOptions<
    T.updateGridImageResponse,
    Error,
    T.updateGridImageRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateGridImage,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update grid image',
        type: 'error'
      })
  })

/**
 * Store grid image - Call
 */
export const storeImageGrid = async (
  payload: T.storeImageGridRequest
): Promise<T.storeImageGridResponse> =>
  await useWretch('FsyncAPI')
    .url(GRID_IMAGES_API_URL)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      ...payload.grid
    })
    .post()
    .json<T.storeImageGridResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Store grid image - mutation
 */
const useStoreImageGrid = (
  options?: UseMutationOptions<
    T.storeImageGridResponse,
    Error,
    T.storeImageGridRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: storeImageGrid,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store grid image',
        type: 'error'
      })
  })

/**
 * Update Image Grid - Call
 */
export const updateImageGrid = async (
  payload: T.updateImageGridRequest
): Promise<T.updateImageGridResponse> =>
  await useWretch('FsyncAPI')
    .url(`${GRID_IMAGES_API_URL}/${payload.sectionBlockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      ...payload.grid
    })
    .put()
    .json<T.updateImageGridResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update Image Grid - mutation
 */
const useUpdateImageGrid = (
  options?: UseMutationOptions<
    T.updateImageGridResponse,
    Error,
    T.updateImageGridRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateImageGrid,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update grid image',
        type: 'error'
      })
  })

/**
 * Delete grid image - Call
 */
export const deleteGridImage = async (
  payload: T.deleteGridImageRequest
): Promise<T.deleteGridImageResponse> =>
  await useWretch('FsyncAPI')
    .url(`${GRID_IMAGES_API_URL}/${payload.sectionBlockId}`)
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      image_id: payload.imageId
    })
    .delete()
    .json<T.deleteGridImageResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * ================================================
 * HTML BLOCK
 * ================================================
 */

const HTML_BLOCK_API_URL = '/api/v2/content/landing-pages/resources/html-block'

/**
 * Save HTML block - Call
 */
export const saveHtmlBlock = async (
  payload: T.storeHtmlBlockRequest
): Promise<T.storeHtmlBlockResponse> =>
  await useWretch('FsyncAPI')
    .url(HTML_BLOCK_API_URL)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      html: payload.html,
      css: payload.css
    })
    .post()
    .json<T.storeHtmlBlockResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Save HTML block - mutation
 */
const useSaveHtmlBlock = (
  options?: UseMutationOptions<
    T.storeHtmlBlockResponse,
    Error,
    T.storeHtmlBlockRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: saveHtmlBlock,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store HTML block',
        type: 'error'
      })
  })

/**
 * Update HTML block - Call
 */
export const updateHtmlBlock = async (
  payload: T.updateHtmlBlockRequest
): Promise<T.updateHtmlBlockResponse> =>
  await useWretch('FsyncAPI')
    .url(`${HTML_BLOCK_API_URL}/${payload.blockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      html: payload.html,
      css: payload.css
    })
    .put()
    .json<T.updateHtmlBlockResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update HTML block - mutation
 */
const useUpdateHtmlBlock = (
  options?: UseMutationOptions<
    T.updateHtmlBlockResponse,
    Error,
    T.updateHtmlBlockRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateHtmlBlock,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update HTML block',
        type: 'error'
      })
  })

/**
 * ================================================
 * Outfit Your Vehicle
 * ================================================
 */

const OUTFIT_YOUR_VEHICLE_API_URL =
  '/api/v2/content/landing-pages/resources/outfit-your-vehicle'

/**
 * Save OutfitYourVehicle - Call
 */
export const storeOutfitYourVehicle = async (
  payload: T.saveOutfitYourVehicleRequest
): Promise<T.saveOutfitYourVehicleResponse> =>
  await useWretch('FsyncAPI')
    .url(OUTFIT_YOUR_VEHICLE_API_URL)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      header: payload.header,
      title: payload.title,
      caption: payload.caption
    })
    .post()
    .json<T.saveOutfitYourVehicleResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Save OutfitYourVehicle - mutation
 */
const useStoreOutfitYourVehicle = (
  options?: UseMutationOptions<
    T.saveOutfitYourVehicleResponse,
    Error,
    T.saveOutfitYourVehicleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: storeOutfitYourVehicle,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store Outfit Your Vehicle',
        type: 'error'
      })
  })

/**
 * Update OutfitYourVehicle - Call
 */
export const updateOutfitYourVehicle = async (
  payload: T.updateOutfitYourVehicleRequest
): Promise<T.updateOutfitYourVehicleResponse> =>
  await useWretch('FsyncAPI')
    .url(`${OUTFIT_YOUR_VEHICLE_API_URL}/${payload.blockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      header: payload.header,
      title: payload.title,
      caption: payload.caption
    })
    .put()
    .json<T.updateOutfitYourVehicleResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update OutfitYourVehicle - mutation
 */
const useUpdateOutfitYourVehicle = (
  options?: UseMutationOptions<
    T.updateOutfitYourVehicleResponse,
    Error,
    T.updateOutfitYourVehicleRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateOutfitYourVehicle,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update Outfit Your Vehicle',
        type: 'error'
      })
  })

/**
 * ================================================
 * Landing Page Slider
 * ================================================
 */

const LANDING_PAGE_SLIDER_API_URL =
  '/api/v2/content/landing-pages/resources/landing-pages-slider'

/**
 * Store Landing Page Slider - Call
 */
export const storeLandingPageSlider = async (
  payload: T.storeLandingPageSliderRequest
): Promise<T.storeLandingPageSliderResponse> =>
  await useWretch('FsyncAPI')
    .url(LANDING_PAGE_SLIDER_API_URL)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      landingPagesSliders: payload.landingPagesSliders.map((v) => v.id)
    })
    .post()
    .json<T.storeLandingPageSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Store Landing Page Slider - mutation
 */
const useStoreLandingPageSlider = (
  options?: UseMutationOptions<
    T.storeLandingPageSliderResponse,
    Error,
    T.storeLandingPageSliderRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: storeLandingPageSlider,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store Landing Page Slider',
        type: 'error'
      })
  })

/**
 * Update Landing Page Slider - Call
 */
export const updateLandingPageSlider = async (
  payload: T.updateLandingPageSliderRequest
): Promise<T.updateLandingPageSliderResponse> =>
  await useWretch('FsyncAPI')
    .url(`${LANDING_PAGE_SLIDER_API_URL}/${payload.sliderId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      sliderId: payload.sliderId,
      landingPagesSliders: payload.landingPagesSliders.map((v) => v.id)
    })
    .put()
    .json<T.updateLandingPageSliderResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update Landing Page Slider - mutation
 */
const useUpdateLandingPageSlider = (
  options?: UseMutationOptions<
    T.updateLandingPageSliderResponse,
    Error,
    T.updateLandingPageSliderRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateLandingPageSlider,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update Landing Page Slider',
        type: 'error'
      })
  })

/**
 * ================================================
 * Design Engineering
 * ================================================
 */

const Design_Engineering_API_URL =
  '/api/v2/content/landing-pages/resources/design-engineering'

/**
 * Get design engineering types - Call
 */
export const getDesignEngineeringTypes = async (
  context: QueryFunctionContext
): Promise<T.getDesignEngineeringTypesResponse> =>
  await useWretch('FsyncAPI')
    .url(Design_Engineering_API_URL)
    .query({ locale: context.queryKey[1] })
    .get()
    .json<T.getDesignEngineeringTypesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get design engineering types - query
 */
const useGetDesignEngineeringTypes = (payload: {
  options?: UseQueryOptions<T.getDesignEngineeringTypesResponse>
  locale: ComputedRef<string>
}) =>
  useQuery({
    queryKey: ['designEngineeringTypes', payload.locale],
    queryFn: getDesignEngineeringTypes,
    ...payload.options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * Store Design Engineering - Call
 */
export const storeDesignEngineering = async (
  payload: T.storeDesignEngineeringRequest
): Promise<T.storeDesignEngineeringResponse> =>
  await useWretch('FsyncAPI')
    .url(`${Design_Engineering_API_URL}/${payload.designEngineeringTypeId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      fields: payload.fields,
      design_engineering_type_id: payload.designEngineeringTypeId
    })
    .put()
    .json<T.storeDesignEngineeringResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Store Design Engineering - mutation
 */
const useStoreDesignEngineering = (
  options?: UseMutationOptions<
    T.storeDesignEngineeringResponse,
    Error,
    T.storeDesignEngineeringRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: storeDesignEngineering,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to store Design Engineering',
        type: 'error'
      })
  })

/**
 * Update Design Engineering - Call
 */
export const updateDesignEngineering = async (
  payload: T.updateDesignEngineeringRequest
): Promise<T.updateDesignEngineeringResponse> =>
  await useWretch('FsyncAPI')
    .url(`${Design_Engineering_API_URL}/${payload.blockId}`)
    .query({ locale: payload.locale })
    .json({
      block_id: payload.blockId,
      page_id: payload.pageId,
      section_block_id: payload.sectionBlockId,
      section_id: payload.sectionId,
      fields: payload.fields,
      design_engineering_type_id: payload.designEngineeringTypeId
    })
    .put()
    .json<T.updateDesignEngineeringResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update Design Engineering - mutation
 */
const useUpdateDesignEngineering = (
  options?: UseMutationOptions<
    T.updateDesignEngineeringResponse,
    Error,
    T.updateDesignEngineeringRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: updateDesignEngineering,
    ...options,
    onError: () =>
      notifier({
        message: 'Unable to update Design Engineering',
        type: 'error'
      })
  })

/**
 * ================================================
 * LOCALES
 * ================================================
 */

/**
 * getLocalesLanguages - Call
 */
export const getLocalesLanguages = async (): Promise<
  {
    value: string
    label: string
  }[]
> =>
  await useWretch('FsyncAPI')
    .get(API_URL_LOCALES)
    .json<T.getLocalesLanguagesResponse>()
    .then((res) => {
      const languagesStrings = Object.entries(res).map(([value, label]) => {
        return { value, label }
      })
      return languagesStrings
    })
    .catch((err) => err)

/**
 * getLocalesLanguages - query
 */
const useGetLocalesLanguages = (
  options?: UseQueryOptions<
    {
      value: string
      label: string
    }[]
  >
) =>
  useQuery({
    queryKey: ['localesLanguages'],
    queryFn: getLocalesLanguages,
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  /**
   * Pages
   */
  useClearCache,
  useGetPages,
  useGetPage,

  /**
   * Sections
   */
  useGetSectionTypes,

  /**
   * Slider
   */
  useGetSliderCategories,
  useGetSlider,

  /**
   * Products
   */

  /**
   * Images
   */

  /**
   * Grid images
   */

  /**
   * HTML BLOCK
   */

  /**
   * OUTFIT YOUR VEHICLE
   */

  /**
   * LANDING PAGE SLIDER
   */

  /**
   * Design Engineering
   */
  useGetDesignEngineeringTypes,

  /**
   * Locales
   */
  useGetLocalesLanguages
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  /**
   * Pages
   */
  useUpdatePage,

  /**
   * Sections
   */
  useUpdateSection,

  /**
   * Slider
   */
  useSaveSlider,
  useUpdateSlider,
  useDeleteSlider,

  /**
   * Products
   */
  useSaveProducts,
  useUpdateProducts,

  /**
   * Images
   */
  useGetImage,
  useStoreImage,
  useUpdateImage,
  useDeleteImage,

  /**
   * Grid images
   */

  // TEXT IN GRID
  useUpdateGridText,

  // IMAGES IN GRID
  useUpdateGridImage,

  // GRID ITSELF
  useStoreImageGrid,
  useUpdateImageGrid,

  // HTML BLOCK
  useSaveHtmlBlock,
  useUpdateHtmlBlock,

  // OUTFIT YOUR VEHICLE
  useStoreOutfitYourVehicle,
  useUpdateOutfitYourVehicle,

  // LANDING PAGE SLIDER
  useStoreLandingPageSlider,
  useUpdateLandingPageSlider,

  // DESIGN ENGINEERING
  useStoreDesignEngineering,
  useUpdateDesignEngineering

  /**
   * Locales
   */
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const contentLandingPagesApiFactory = {
  queries,
  mutations
}
