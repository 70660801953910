/**
 * This is the service desk factory, it contains all the api calls related to service desk Feature on Campfire.
 */
import type { UseMutationOptions, UseQueryOptions } from '@tanstack/vue-query'

import { useMutation, useQuery } from '@tanstack/vue-query'
import type * as T from './types'
import { Dialog } from 'quasar'
import { fsyncAPIInstance } from '~/src/boot/axios'
import useAuthStore from '~/features/auth/store'

/**
 * API Routes
 */
export const API_PREFIX = '/api/v2'

/**
 * get Wrike Users
 *
 * Custom hook for fetching Wrike users.
 * @param options - Query options for useQuery.
 * @returns The result of the useQuery hook.
 */
const useGetWrikeUsers = (
  options?: UseQueryOptions<T.getWrikeUsersResponse['wrikeUsers']>
) =>
  useQuery({
    queryKey: ['getWrikeUsers'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/service-desk/wrike/users`)
        .get()
        .json<T.getWrikeUsersResponse>()
        .then((res) => res.wrikeUsers)
        .catch((err) => err),
    ...options
  })

/**
 * get Wrike User
 *
 * Custom hook for fetching the Wrike user information.
 * @param options - Query options for useQuery.
 * @returns The result of the useQuery hook.
 */
export const useGetWrikeUser = (
  options?: UseQueryOptions<T.getWrikeUserResponse>
) =>
  useQuery({
    queryKey: ['wrikeUser'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/service-desk/wrike/user`)
        .get()
        .json<T.getWrikeUserResponse>()
        .then((res) => res)
        .catch((err) => err),
    refetchOnWindowFocus: false,
    ...options
  })

/**
 * get Wrike Issue Types
 *
 * Custom hook for fetching the list of Wrike issue types.
 * @param options - Query options for useQuery.
 * @returns The result of the useQuery hook.
 */
const useGetWrikeIssueTypes = (
  options?: UseQueryOptions<T.getWrikeIssueTypesResponse['issueTypes'][0]>
) =>
  useQuery({
    queryKey: ['getWrikeIssueTypes'],
    queryFn: () =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/service-desk/wrike/issue-types`)
        .get()
        .json<T.getWrikeIssueTypesResponse>()
        .then((res) => ({
          id: 0,
          name: 'Select Issue Type',
          description: '',
          parent_id: 0,
          assignees: '',
          folder: '',
          groups: null,
          icon: '',
          item_order: 0,
          deleted: 0,
          created_at: '',
          updated_at: '',
          children: [
            ...res.issueTypes,
            {
              id: -1,
              name: 'Self Help',
              description: '',
              parent_id: 0,
              assignees: '',
              folder: '',
              groups: null,
              icon: '',
              item_order: 0,
              deleted: 0,
              created_at: '',
              updated_at: '',
              children: [
                {
                  id: -2,
                  name: 'Reset Magento password',
                  icon: '<i class="fa fa-lock" aria-hidden="true"></i>'
                }
              ]
            }
          ],
          parent: null
        }))
        .catch((err) => err),
    ...options
  })

/**
 * get Wrike Issue Types
 *
 * Custom hook for resetting Magento password.
 * @param options - Options for the mutation.
 * @returns A function that can be used to reset the Magento password.
 */
const useResetMagentoPassword = (
  options?: UseMutationOptions<
    T.resetMagentoPasswordResponse,
    unknown,
    string,
    unknown
  >
) =>
  useMutation({
    mutationFn: (username) =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/service-desk/wrike/reset-magento-password`)
        .json({ username })
        .post()
        .json<T.resetMagentoPasswordResponse>()
        .then((res) => res)
        .catch((err) => err),
    ...options
  })

/**
 * create Wrike Ticket
 *
 * Custom hook for creating a Wrike ticket.
 * @param options - Options for the mutation.
 * @returns A function that can be used to create a Wrike ticket.
 */
const useCreateWrikeTicket = (
  options?: UseMutationOptions<
    T.createWrikeTicketResponse,
    T.createWrikeTicketError, // unknown
    T.createWrikeTicketRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: async (ticket) =>
      fsyncAPIInstance
        .post<T.createWrikeTicketResponse>(
          `${API_PREFIX}/service-desk/wrike/create-issue?campfire_user_id=${useAuthStore().user?.id} `,
          //API_PREFIX + '/service-desk/wrike/create-issue',
          ticket,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${useTokenSwitch('azure')}`,
              Accept: '*/*'
            }
          }
        )
        .then((res) => {
          console.log('res.data')
          console.log(res.data)
          return res.data
        })
        .catch((err) => {
          console.log(err)
          throw err
        }),
    // useWretch('FsyncAPI') // incase
    //   .url(`${API_PREFIX}/service-desk/wrike/create-issue`)
    //   .headers({
    //     'Content-Type': 'multipart/form-data',
    //     Accept: '*/*'
    //   })
    //   //.formData(ticket)
    //   .post(ticket)
    //   .json<T.createWrikeTicketResponse>() // incase
    //   .then((res) => res)
    //   .catch((err) => {
    //     Dialog.create({
    //       message: err.message,
    //       color: 'red',
    //       html: true
    //     })

    //     throw err
    //   }),
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetWrikeUsers,
  useGetWrikeUser,
  useGetWrikeIssueTypes
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useResetMagentoPassword,
  useCreateWrikeTicket
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const serviceDeskApiFactory = {
  queries,
  mutations
}
