/**
 * ================================================
 * Warehouse carrier labels API factory
 * This factory contains all the methods for interacting with the warehouse carrier labels feature.
 * ================================================
 */

/**
 * ================================================
 * NOTES: 👍
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import {
  type QueryFunctionContext,
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import { usePickingDashboardStore } from '../../../picking-dashboard/store'

/**
 * API Routes
 */
const API_PREFIX = '/api/v2/warehouse'
const API_PREFIX_V1 = '/api/v1/location'

let previousCountry: string | null = null
//const warehouseOrdersStore = usePickingDashboardStore()

/**
 * Get Werehouse Shipments - Call
 *
 * Retrieves warehouse shipments based on the provided payload.
 * @param payload - The request payload containing information about the warehouse shipments.
 * @returns A promise that resolves to the response containing the warehouse shipments.
 */
export const getWarehouseShipments = async (
  payload: T.warehouseGetWarehouseShipmentsRequest
): Promise<T.warehouseGetWarehouseShipmentsResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/shipments/region/${payload.country}`)
    .query({
      warehouseLocation: payload.warehouseLocation
    })
    .json(payload.pagination)
    .post()
    .json<T.warehouseGetWarehouseShipmentsResponse>()
    .then((res) => {
      // reset previous country to latest country
      previousCountry = payload.country

      return {
        ...res,
        pagination: {
          ...res.pagination,
          filter: res.filter
        }
      }
    })
    .catch((err) => err)

/**
 * Get Werehouse Shipments - Query
 *
 * Retrieves warehouse shipments based on the provided payload.
 * @param payload - The payload containing the necessary information for retrieving warehouse shipments.
 * @returns The result of the query for warehouse shipments.
 */
const useGetWarehouseShipments = (payload: {
  country: ComputedRef<string>
  warehouseLocation: ComputedRef<string>
  pagination: ComputedRef<{
    descending: boolean
    filter?: string | null
    page: number
    rowsNumber?: number
    rowsPerPage: number
    sortBy: string
  }>
  options?: UseQueryOptions<T.warehouseGetWarehouseShipmentsResponse>
}) => {
  const currentWarehouseLocation = ref<string>(payload.warehouseLocation.value)

  // when a country is changed - use FIN_MSTR as the first warehouse that loads
  watch(
    () => payload.country.value,
    (newCountry, oldCountry) => {
      if (newCountry !== oldCountry) {
        currentWarehouseLocation.value = 'FIN_MSTR'
      }
    }
  )

  watch(
    () => payload.warehouseLocation.value,
    (newLocation) => {
      currentWarehouseLocation.value = newLocation
    }
  )

  return useQuery({
    queryKey: [
      'warehouseGetWarehouseShipments',
      payload.country,
      currentWarehouseLocation,
      payload.pagination
    ],
    queryFn: () =>
      getWarehouseShipments({
        country: payload.country.value,
        warehouseLocation: currentWarehouseLocation.value,
        pagination: payload.pagination.value
      }),
    ...payload.options
  })
}

/**
 * Get Werehouse Carriers - Call
 *
 * Get the list of available carriers for a given region and sets them in the store
 * @param region "RSA" | "USA" | "GER" | "AUS"
 */
export const getWerehouseCarriers = async (
  context: QueryFunctionContext
): Promise<{
  carriers: T.warehouseCarrier[]
  carrier: T.warehouseCarrier
  carrierService: T.CarrierService
}> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/carriers/${context.queryKey[1]}`)
    .get()
    .json((carriers: T.warehouseCarrier[]) => {
      const warehouseCarriers = {
        carriers: [],
        carrier: null,
        carrierService: null
      } as {
        carriers: T.warehouseCarrier[]
        carrier: T.warehouseCarrier
        carrierService: T.CarrierService
      }

      warehouseCarriers.carriers = carriers

      if (warehouseCarriers.carriers.length === 1) {
        ;[warehouseCarriers.carrier] = carriers
      } else {
        warehouseCarriers.carrier = null
      }

      // Select service if its only one
      if (warehouseCarriers.carrier?.services.length === 1) {
        // [warehouseCarriers.carrierService] = warehouseCarriers?.carrier?.services
      } else {
        warehouseCarriers.carrierService = null
      }

      return warehouseCarriers
    })
    .catch((err) => err)

/**
 * Get Werehouse Carriers - Query
 *
 * Custom hook for fetching warehouse carriers.
 * @param payload - The payload object.
 * @param payload.region - The region to fetch warehouse carriers for.
 * @param payload.options - The options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
const useGetWerehouseCarriers = (payload: {
  region: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetWerehouseCarriersResponse>
}) =>
  useQuery({
    queryKey: ['warehouseGetWerehouseCarriers', payload.region],
    queryFn: getWerehouseCarriers,
    ...payload.options
  })

/**
 * Get Suggested Places logistical solutionist - call
 *
 * Retrieves suggested places for a city based on the provided payload.
 * @param payload - The request payload containing the necessary information.
 * @returns A promise that resolves to the response containing the suggested places.
 */
export const getSuggestedPlacesLogisticalSolutionist = async (
  payload: T.warehouseGetSuggestionsFromLogisticalSolutionistRequest
): Promise<T.warehouseGetSuggestionsFromLogisticalSolutionistResponse> => {
  return await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/places/logistical-solutionist`)
    .query({
      name: payload.name,
      postalCode: payload.postalCode
    })
    .get()
    .json<T.warehouseGetSuggestionsForCityResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * Get Suggested Places logistical solutionist - Query
 *
 * Custom hook for retrieving suggested places based on city, postal code, country, and carrier.
 * @param payload - The payload object containing the necessary parameters.
 * @returns The result of the query.
 */
const useGetSuggestedPlacesLogisticalSolutionist = (payload: {
  name: ComputedRef<string>
  postalCode: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetSuggestionsFromLogisticalSolutionistResponse>
}) =>
  useQuery({
    queryKey: [
      'getSuggestedPlacesLogisticalSolutionist',
      payload.name,
      payload.postalCode
    ],
    queryFn: () =>
      getSuggestedPlacesLogisticalSolutionist({
        name: payload.name.value,
        postalCode: payload.postalCode.value
      }),

    ...payload.options,
    enabled: !!payload.name.value && !!payload.postalCode.value
  })

/**
 * get suggested places aramex - call
 */

export const getSuggestedPlacesAramex = async (payload: {
  postcode: string
  country: string
}): Promise<T.warehouseGetSuggestionsFromAramexResponse> => {
  return await useWretch('FsyncAPI')
    .url(API_PREFIX_V1)
    .query({
      postcode: payload.postcode,
      country: payload.country
    })
    .get()
    .json<T.warehouseGetSuggestionsFromAramexResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * get suggested places aramex - query
 */
const useGetSuggestedPlacesAramex = (payload: {
  postcode: ComputedRef<string>
  country: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetSuggestionsFromAramexResponse>
}) =>
  useQuery({
    queryKey: ['getSuggestedPlacesAramex', payload.postcode, payload.country],
    queryFn: () =>
      getSuggestedPlacesAramex({
        postcode: payload.postcode.value,
        country: payload.country.value
      }),
    ...payload.options,
    enabled: !!payload.postcode.value
  })

/**
 * Create Shipment - call
 *
 * Creates a shipment with the given payload.
 * @param payload - The request payload for creating a shipment.
 * @returns A promise that resolves to the response of getting the shipment price.
 */
// export const createShipment = async (
//   payload: T.warehouseCreateShipmentRequest
// ): Promise<T.warehouseGetShipmentPriceResponse> =>
//   await useWretch('FsyncAPI')
//     .url(`${API_PREFIX}/shipments`)
//     .json(payload)
//     .catcher(412, (err) => {
//       notifier({
//         message: err.message,
//         type: 'error'
//       })

//       throw err
//     })
//     .post()
//     .json<T.warehouseGetShipmentPriceResponse>()
//     .then((res) => res)
//     .catch((err) => err)

export const createShipment = async (
  payload: T.warehouseCreateShipmentRequest
): Promise<T.warehouseGetShipmentPriceResponse> => {
  try {
    const response = await useWretch('FsyncAPI')
      .url(`${API_PREFIX}/shipments`)
      .json(payload)
      .catcher(412, (err) => {
        return Promise.reject(err)
      })
      .post()
      .json<T.warehouseGetShipmentPriceResponse>()

    return response
  } catch (err) {
    console.log('Error during shipment creation: ', err)
    throw err
  }
}

/**
 * Create Shipment - mutation
 *
 * Custom hook for creating a shipment.
 * @param options - Optional mutation options.
 * @returns A mutation function for creating a shipment.
 */
const useCreateShipment = (
  options?: UseMutationOptions<
    T.warehouseCreateShipmentResponse,
    T.warehouseCreateShipmentError,
    T.warehouseCreateShipmentRequest,
    unknown
  >
) =>
  useMutation({
    //mutationFn: createShipment,
    mutationFn: async (
      payload: T.warehouseCreateShipmentRequest
    ): Promise<T.warehouseGetShipmentPriceResponse> =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}/shipments`)
        .json(payload)
        .catcher(412, (err) => {
          throw err
        })
        .post()
        .json<T.warehouseGetShipmentPriceResponse>()
        .then((res) => res)
        .catch((err) => {
          throw err
        }),
    ...options
  })

/**
 * Get Shipment price from courier - call
 *
 * Retrieves the shipment price based on the provided payload.
 * @param payload The request payload for getting the shipment price.
 * @returns A promise that resolves to the response containing the shipment price.
 */
export const getShipmentPrice = async (
  payload: T.warehouseGetShipmentPriceRequest
): Promise<T.warehouseGetShipmentPriceResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/shipments/rates/quote`)
    .json(payload)
    .post()
    .json<T.warehouseGetShipmentPriceResponse>()
    .then((response) => {
      return response
    })

/**
 * Get Shipment price from courier - mutation
 *
 * Custom hook for getting the shipment price.
 * @param options - Optional mutation options.
 * @returns A mutation function for getting the shipment price.
 */
const useGetShipmentPrice = (
  options?: UseMutationOptions<
    T.warehouseGetShipmentPriceResponse,
    T.warehouseGetShipmentPriceError,
    T.warehouseGetShipmentPriceRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: getShipmentPrice,
    ...options
  })

/**
 * Get Data for Parcel Configuration - Call
 *
 * Retrieves the parcel configuration for a given order from the warehouse API.
 * @param payload - The request payload containing the order ID and region.
 * @returns A promise that resolves to the response containing the parcel configuration.
 */
export const getParcelConfigurator = async (
  payload: T.warehouseGetParcelConfigurationRequest
): Promise<T.warehouseGetParcelConfigurationResponse> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/shipments/${payload.order_id}`)
    .query({
      warehouseLocations: payload.warehouseLocation,
      region: payload.region
    })
    .get()
    .json<T.warehouseGetParcelConfigurationResponse>()
    .then((res) => {
      let existingParcels = []
      if (res.packages.length > 0) {
        let p

        for (p = 0; p < res.packages.length; p++) {
          const {
            id,
            name,
            weight,
            dim_length,
            dim_width,
            dim_height,
            insurance_value,
            shipped,
            shipped_at
          } = res.packages[p]

          existingParcels.push({
            expansionItem: p === 0,
            id: id,
            title: name,
            weight: weight,
            length: dim_length,
            width: dim_width,
            height: dim_height,
            insurance_value: insurance_value,
            shipped: shipped,
            shipped_at: shipped_at,
            items: []
          })
        }
      } else {
        // Set parcels to empty template
        existingParcels = [
          {
            expansionItem: true,
            id: null,
            title: 'Parcel 1',
            weight: null,
            length: null,
            width: null,
            height: null,
            qty: 1,
            qty_shipped: 0,
            shipped: 0,
            shipped_at: null,
            insurance_value: null,
            items: []
          }
        ]
      }

      return {
        ...res,
        parcels: existingParcels
      }
    })
    .catch((err) => err)

/**
 * Get Data for Parcel Configuration - Query
 *
 * Fetches the parcel configuration for a given region and order ID.
 * @param region - The region for which to fetch the parcel configuration.
 * @param orderId - The ID of the order for which to fetch the parcel configuration.
 * @param options - Optional query options for the useQuery hook.
 * @returns The result of the useQuery hook for fetching the parcel configuration.
 */
const useParcelConfigurator = ({
  warehouseLocation,
  orderId,
  region,
  options
}: {
  warehouseLocation: ComputedRef<string | string[]>
  orderId: ComputedRef<string>
  region: string
  options?: UseQueryOptions<T.warehouseGetParcelConfigurationResponse, Error>
}) =>
  useQuery({
    queryKey: ['parcelConfigurator', warehouseLocation, orderId, region],
    queryFn: () =>
      getParcelConfigurator({
        warehouseLocation:
          typeof warehouseLocation.value === 'string'
            ? warehouseLocation.value
            : warehouseLocation.value.join(','),
        order_id: orderId.value,
        region: region
      }),
    refetchOnWindowFocus: false,
    ...options
  })

/**
 * Get Werehouse Shipment Details - Call
 *
 * Retrieves warehouse shipment details.
 * @param payload - The request payload.
 * @returns A promise that resolves to the warehouse shipment details response.
 */
export async function getWarehouseShipmentDetails(
  payload: T.warehouseGetWarehouseShipmentDetailsRequest
): Promise<T.warehouseGetWarehouseShipmentDetailsResponse> {
  return await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/shipments/${payload.order_id}`)
    .get()
    .json<T.warehouseGetWarehouseShipmentDetailsResponse>()
    .then((res) => res)
    .catch((err) => err)
}

/**
 * Get Werehouse Shipment Details - Query
 *
 * Retrieves warehouse shipment details for a given order ID.
 * @param payload - The payload object containing the order ID and optional query options.
 * @returns The result of the query to get warehouse shipment details.
 */
const useGetWarehouseShipmentDetails = (payload: {
  orderId: ComputedRef<string>
  options?: UseQueryOptions<T.warehouseGetWarehouseShipmentDetailsResponse>
}) =>
  useQuery({
    queryKey: ['warehouseGetWarehouseShipmentDetails', payload.orderId],
    queryFn: () =>
      getWarehouseShipmentDetails({ order_id: payload.orderId.value }),
    ...payload.options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetWarehouseShipments,
  useGetWerehouseCarriers,
  useGetSuggestedPlacesLogisticalSolutionist,
  useParcelConfigurator,
  getWarehouseShipmentDetails,
  useGetWarehouseShipmentDetails,
  useGetSuggestedPlacesAramex
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useCreateShipment,
  useGetShipmentPrice
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const warehouseCarrierLabelsApiFactory = {
  queries,
  mutations
}
