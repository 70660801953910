/**
 * ================================================
 * VFITS Factory API
 * This factory contains all the methods for interacting with the VFITS feature.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import qs from 'qs'
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import { Notify } from 'quasar'
/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'
import { usePagesStore } from '~/features/content/landing-pages/store'

/**
 * ================================================
 * API Routes
 * ================================================
 */
const API_PREFIX = '/api/v2/vfits'

// clear cache
export const useClearVfitsCache = (
  options?: UseMutationOptions<
    T.clearViftsCacheResponse,
    unknown,
    T.clearViftsCacheRequest,
    unknown
  >
) =>
  useMutation({
    mutationFn: async (
      params: T.clearViftsCacheRequest
    ): Promise<T.clearViftsCacheResponse> => {
      try {
        const response = await useWretch('FsyncAPI')
          .url(`${API_PREFIX}/cache-clear?type=${params.optionToClear}`)
          .delete()
          .json<T.clearViftsCacheResponse>()

        return response
      } catch (error) {
        console.log('error clearing vfits cache')
        throw error
      }
    },
    ...options
  })

/**
 * ================================================
 * Makes
 * ================================================
 */

/**
 * Get Makes - Call | Retrieves a list of vehicle makes from the API.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const getMakes = async (
  payload: T.vfitsGetMakesRequest
): Promise<T.vfitsGetMakesResponse> =>
  await useWretch('FsyncAPI')
    .get(
      `${API_PREFIX}/makes?${qs.stringify({
        ...payload,
        locale: payload?.locale || 'en'
      })}`
    )
    .json<T.vfitsGetMakesResponse>()
    .then((res) => {
      if (Array.isArray(res) && payload.withModels) {
        res = res.filter(({ modelsCount }) => Boolean(modelsCount))
      }
      return res
    })
    .catch((err) => err)

/**
 * Get Makes - Query | Custom Query hook to fetch makes data from the API.
 * @param payload - An object containing optional parameters for the API request.
 * @param payload.active - A computed ref for the active parameter.
 * @param payload.withModels - A computed ref for the withModels parameter.
 * @param payload.locale - A computed ref for the locale parameter.
 * @param payload.options - Optional React Query options.
 * @returns The result of the React Query `useQuery` hook.
 */
const useGetMakes = (payload: {
  active?: ComputedRef<number | undefined>
  withModels?: ComputedRef<number | undefined>
  locale?: ComputedRef<string | undefined>
  options?: UseQueryOptions<T.vfitsGetMakesResponse>
}) =>
  useQuery({
    queryKey: [
      'getMakes',
      payload.active,
      payload.withModels,
      payload?.locale || 'en'
    ],
    queryFn: () =>
      getMakes({
        active: payload.active?.value,
        withModels: payload.withModels?.value,
        locale: payload.locale?.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * Create Make - Call | Creates a new make using the provided payload.
 * @param payload - The payload for creating a new make.
 * @returns A promise that resolves to the response of the create makes API call.
 * @throws An error if the API call fails.
 */
export const postNewMake = async (
  payload: T.vfitsCreateMakesRequest
): Promise<T.vfitsCreateMakesResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/makes?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsCreateMakesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Create Make - Mutation | A custom hook that returns a `useMutation` function for creating new makes.
 * @param payload An optional object containing `options` for the `useMutation` function.
 * @returns A `useMutation` function for creating new makes.
 */
const usePostNewMake = (payload?: {
  options?: UseMutationOptions<
    T.vfitsCreateMakesResponse,
    unknown,
    T.vfitsCreateMakesRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: postNewMake,
    ...payload?.options
  })

/**
 * Update Make - Call | Updates a make in the VFITS API.
 * @param payload - The request payload containing the make data to update.
 * @returns A promise that resolves to the response data from the API.
 * @throws An error if the API call fails.
 * @example
 * ```
 * const response = await putMake({
 *   id: 123,
 *   name: 'Ford',
 *   country: 'USA'
 * });
 * ```
 */
export const putMake = async (
  payload: T.vfitsUpdateMakesRequest
): Promise<T.vfitsUpdateMakesResponse> =>
  await useWretch('FsyncAPI')
    .put(
      JSON.stringify(payload),
      `${API_PREFIX}/makes/${payload.id}?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsUpdateMakesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update Make - Mutation | A custom hook that returns a `useMutation` function for updating a make in the vFits API.
 * @param payload An optional object containing `options` to pass to the `useMutation` function.
 * @returns A `useMutation` function for updating a make in the vFits API.
 */
const usePutMake = (payload?: {
  options?: UseMutationOptions<
    T.vfitsUpdateMakesResponse,
    unknown,
    T.vfitsUpdateMakesRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: putMake,
    ...payload?.options
  })

/**
 * Delete Make - Call | Deletes a make from the API.
 * @param payload - The request payload containing the ID of the make to be deleted.
 * @returns A promise that resolves to the response from the API.
 * @throws An error if the request fails.
 */
export const deleteMake = async (
  payload: T.vfitsDeleteMakesRequest
): Promise<T.vfitsDeleteMakesResponse> =>
  await useWretch('FsyncAPI')
    .delete(
      `${API_PREFIX}/makes/${payload.id}?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteMakesResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete Make - Mutation | A custom hook that returns a `useMutation` function for deleting a make.
 * @param payload An optional object containing `options` for the `useMutation` function.
 * @returns A `useMutation` function for deleting a make.
 */
const useDeleteMake = (payload?: {
  options?: UseMutationOptions<
    T.vfitsDeleteMakesResponse,
    unknown,
    T.vfitsDeleteMakesRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteMake,
    ...payload?.options
  })

/**
 * Get Make Models - Call | Retrieves make models from the API.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const getMakeModels = async (
  payload: T.vfitsGetMakeModelsRequest
): Promise<T.vfitsGetMakeModelsResponse> =>
  await useWretch('FsyncAPI')
    .get(
      `${API_PREFIX}/makes/${payload.makeId}/models?${qs.stringify({
        active: payload?.active || 0,
        locale: payload?.locale || 'en'
      })}`
    )
    .json<T.vfitsGetMakeModelsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Make Models - Query | A custom hook that returns a query object for fetching make models data.
 * @param payload An object containing the makeId, active, locale, and options for the query.
 * @returns A query object for fetching make models data.
 */
const useGetMakeModels = (payload: {
  makeId: ComputedRef<number>
  active?: ComputedRef<number>
  locale?: ComputedRef<string>
  options?: UseQueryOptions<T.vfitsGetMakeModelsResponse>
}) =>
  useQuery({
    queryKey: [
      'getMakeModels',
      payload.makeId,
      payload.active,
      payload?.locale || 'en'
    ],
    queryFn: () =>
      getMakeModels({
        makeId: payload.makeId.value,
        active: payload.active?.value,
        locale: payload.locale?.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * Create Make Model - Call | Creates a new make model.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response payload.
 */
export const postNewMakeModel = async (
  payload: T.vfitsCreateMakeModelRequest
): Promise<T.vfitsCreateMakeModelResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/makes/${payload.makeId}/models?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsCreateMakeModelResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Create Make Model - Mutation | A custom hook that returns a mutation function for creating a new make and model in the vFits API.
 * @param payload An optional object containing options to pass to the `useMutation` hook.
 * @returns A mutation function for creating a new make and model in the vFits API.
 */
const usePostNewMakeModel = (payload?: {
  options?: UseMutationOptions<
    T.vfitsCreateMakeModelResponse,
    unknown,
    T.vfitsCreateMakeModelRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: postNewMakeModel,
    ...payload?.options
  })

/**
 * Update Make Model - Call | Updates a make model in the VFITS API.
 * @param payload - The request payload containing the make model data to update.
 * @returns A promise that resolves to the response data from the API.
 * @throws An error if the API call fails.
 * @example
 * ```
 * const response = await putMakeModel({
 *   makeId: '123',
 *   id: '456',
 *   name: 'New Model Name'
 * });
 * ```
 */
export const putMakeModel = async (
  payload: T.vfitsUpdateMakeModelRequest
): Promise<T.vfitsUpdateMakeModelResponse> =>
  await useWretch('FsyncAPI')
    .put(
      JSON.stringify(payload),
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.id
      }?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsUpdateMakeModelResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Update Make Model - Mutation | A custom React hook that returns a `useMutation` function for updating a vehicle make and model.
 *
 * @param payload - An optional object containing `options` to pass to the `useMutation` function.
 * @returns A `useMutation` function for updating a vehicle make and model.
 */
const usePutMakeModel = (payload?: {
  options?: UseMutationOptions<
    T.vfitsUpdateMakeModelResponse,
    unknown,
    T.vfitsUpdateMakeModelRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: putMakeModel,
    ...payload?.options
  })

/**
 * Delete Make Model - Call | Deletes a make and model from the API.
 * @param payload - The request payload containing the make and model IDs.
 * @returns A promise that resolves to the response from the API.
 * @throws An error if the request fails.
 * @example
 * ```
 * const response = await deleteMakeModel({ makeId: 1, modelId: 2 });
 * console.log(response); // { success: true }
 * ```
 */
export const deleteMakeModel = async (
  payload: T.vfitsDeleteMakeModelRequest
): Promise<T.vfitsDeleteMakeModelResponse> =>
  await useWretch('FsyncAPI')
    .delete(
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.modelId
      }?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteMakeModelResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Delete Make Model - Mutation | A custom hook that returns a mutation function for deleting a make and model from the vFits API.
 * @param payload An optional object containing options to configure the mutation function.
 * @returns A mutation function for deleting a make and model from the vFits API.
 */
const useDeleteMakeModel = (payload?: {
  options?: UseMutationOptions<
    T.vfitsDeleteMakeModelResponse,
    unknown,
    T.vfitsDeleteMakeModelRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteMakeModel,
    ...payload?.options
  })

/**
 * getModelOptions - Call | Retrieves the options for a specific vehicle model.
 * @param payload - The request payload containing the makeId, modelId, active and locale parameters.
 * @returns A promise that resolves to the response containing the options for the specified vehicle model.
 */
export const getModelOptions = async (
  payload: T.vfitsGetModelOptionsRequest
): Promise<T.vfitsGetModelOptionsResponse> =>
  await useWretch('FsyncAPI')
    .get(
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.modelId
      }/options?${qs.stringify({
        active: payload?.active || 0,
        locale: payload?.locale || 'en'
      })}`
    )
    .json<T.vfitsGetModelOptionsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * getModelOptions - Query | A custom hook that returns a `useQuery` instance for fetching model options data.
 * @param payload An object containing the required and optional parameters for the query.
 * @returns A `useQuery` instance for fetching model options data.
 */
const useGetModelOptions = (payload: {
  makeId: ComputedRef<number>
  modelId: ComputedRef<number>
  locale?: ComputedRef<string>
  active?: ComputedRef<number>
  options?: UseQueryOptions<T.vfitsGetModelOptionsResponse>
}) =>
  useQuery({
    queryKey: [
      'getModelOptions',
      payload.makeId,
      payload.modelId,
      payload?.locale || 'en',
      payload.active
    ],
    queryFn: () =>
      getModelOptions({
        makeId: payload.makeId.value,
        modelId: payload.modelId.value,
        locale: payload.locale?.value,
        active: payload.active?.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * createModelOption - Call | Creates a new model option.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const createModelOption = async (
  payload: T.vfitsCreateModelOptionRequest
): Promise<T.vfitsCreateModelOptionResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.modelId
      }/options?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsCreateModelOptionResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * createModelOption - Mutation | A custom hook that returns a `useMutation` function with the `createModelOption` mutation and optional mutation options.
 * @param payload An optional object containing mutation options.
 * @returns A `useMutation` function with the `createModelOption` mutation and optional mutation options.
 */
const useCreateModelOption = (payload?: {
  options?: UseMutationOptions<
    T.vfitsCreateModelOptionResponse,
    unknown,
    T.vfitsCreateModelOptionRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: createModelOption,
    ...payload?.options
  })

/**
 * updateModelOption - Call | Updates a model option.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const updateModelOption = async (
  payload: T.vfitsUpdateModelOptionRequest
): Promise<T.vfitsUpdateModelOptionResponse> =>
  await useWretch('FsyncAPI')
    .put(
      JSON.stringify(payload),
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.modelId
      }/options/${payload.id}?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsUpdateModelOptionResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * updateModelOption - Mutation | A custom hook that returns a mutation function for updating a model option in the VFITS API.
 * @param payload An optional object containing options to pass to the `useMutation` hook.
 * @returns A mutation function for updating a model option.
 */
const useUpdateModelOption = (payload?: {
  options?: UseMutationOptions<
    T.vfitsUpdateModelOptionResponse,
    unknown,
    T.vfitsUpdateModelOptionRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: updateModelOption,
    ...payload?.options
  })

/**
 * deleteModelOption - Call | Deletes a model option.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const deleteModelOption = async (
  payload: T.vfitsDeleteModelOptionRequest
): Promise<T.vfitsDeleteModelOptionResponse> =>
  await useWretch('FsyncAPI')
    .delete(
      `${API_PREFIX}/makes/${payload.makeId}/models/${
        payload.modelId
      }/options/${payload.optionId}?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteModelOptionResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * deleteModelOption - Mutation | A custom hook that returns a mutation function for deleting a model option.
 * @param payload An optional object containing options to configure the mutation.
 * @returns A mutation function that can be called to delete a model option.
 */
const useDeleteModelOption = (payload?: {
  options?: UseMutationOptions<
    T.vfitsDeleteModelOptionResponse,
    unknown,
    T.vfitsDeleteModelOptionRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteModelOption,
    ...payload?.options
  })

/**
 * Get Make Model Option Years-products - Call | Retrieves the available years and products for a given make, model, and option ID.
 * @param payload - The request payload containing the make ID, model ID, option ID, and locale.
 * @returns A promise that resolves to the response containing the available years and products.
 */
export const getMakeModelOptionYearsProducts = async (
  payload: T.vfitsGetMakeModelOptionYearsProductsRequest
): Promise<T.vfitsGetMakeModelOptionYearsProductsResponse> =>
  await useWretch('FsyncAPI')
    .url(
      `${API_PREFIX}/makes/${payload.makeId}/models/${payload.modelId}/options/${payload.optionId}/years-products`
    )
    .query({
      locale: payload?.locale || 'en'
    })
    .get()
    .json<T.vfitsGetMakeModelOptionYearsProductsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * Get Make Model Option Years-products - Query | Fetches the products for a given make, model, and option, along with the available years for each product.
 * @param payload An object containing the makeId, modelId, optionId, active, locale, and options for the query.
 * @returns A useQuery object with the fetched data.
 */
const useGetMakeModelOptionYearsProducts = (payload: {
  makeId: ComputedRef<number>
  modelId: ComputedRef<number>
  optionId: ComputedRef<number>
  active?: ComputedRef<number>
  locale?: ComputedRef<string>
  options?: UseQueryOptions<T.vfitsGetMakeModelOptionYearsProductsResponse>
}) =>
  useQuery({
    queryKey: [
      'getMakeModelOptionYearsProducts',
      payload.makeId,
      payload.modelId,
      payload.optionId,
      payload.active,
      payload?.locale || 'en'
    ],
    queryFn: () =>
      getMakeModelOptionYearsProducts({
        makeId: payload.makeId.value,
        modelId: payload.modelId.value,
        optionId: payload.optionId.value,
        active: payload.active?.value,
        locale: payload.locale?.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * addProductsToVehicle - Call | Adds products to a vehicle.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const addProductsToVehicle = async (
  payload: T.vfitsAddProductsToVehicleRequest
): Promise<T.vfitsAddProductsToVehicleResponse> =>
  await useWretch('FsyncAPI')
    .put(
      JSON.stringify(payload),
      `${API_PREFIX}/productsToVehicle?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsAddProductsToVehicleResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * addProductsToVehicle - Mutation | A custom hook that returns a mutation function to add products to a vehicle.
 * @param payload An optional object containing options to configure the mutation.
 * @returns A mutation function to add products to a vehicle.
 */
const useAddProductsToVehicle = (payload?: {
  options?: UseMutationOptions<
    T.vfitsAddProductsToVehicleResponse,
    unknown,
    T.vfitsAddProductsToVehicleRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: addProductsToVehicle,
    ...payload?.options
  })

/**
 * deleteProductFromYear - Call | Deletes a product from a year in the VFITS API.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response from the API.
 */
export const deleteProductFromYear = async (
  payload: T.vfitsDeleteProductFromYearRequest
): Promise<T.vfitsDeleteProductFromYearResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/productFromVehicle?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteProductFromYearResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * deleteProductFromYear - Mutation | A custom hook that returns a mutation function to delete a product from a year in the VFITS API.
 * @param payload An object containing optional `options` to configure the mutation.
 * @returns A mutation function to delete a product from a year in the VFITS API.
 */
const useDeleteProductFromYear = (payload: {
  options?: UseMutationOptions<
    T.vfitsDeleteProductFromYearResponse,
    unknown,
    T.vfitsDeleteProductFromYearRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteProductFromYear,
    ...payload.options
  })

/**
 * getProductVehiclesBySku - Call | Retrieves the vehicles associated with a product SKU.
 * @param payload - The request payload.
 * @returns A promise that resolves to an array of vehicles.
 */
export const getProductVehiclesBySku = async (
  payload: T.vfitsGetProductVehiclesBySkuRequest
): Promise<T.vfitsGetProductVehiclesBySkuResponse['vehicles']> =>
  await useWretch('FsyncAPI')
    .get(
      `${API_PREFIX}/products/${payload.sku}/vehicles?${qs.stringify({
        locale: payload?.locale || 'en'
      })}`
    )
    .json<T.vfitsGetProductVehiclesBySkuResponse>()
    .then((res) => res.vehicles)
    .catch((err) => err)

/**
 * getProductVehiclesBySku - Query | Custom hook that returns a query object for fetching vehicles associated with a product SKU.
 * @param payload - An object containing the SKU and locale, as well as optional query options.
 * @returns A query object with the vehicles associated with the given SKU.
 */
const useGetProductVehiclesBySku = (payload: {
  sku: ComputedRef<string>
  locale: ComputedRef<string>
  options?: UseQueryOptions<T.vfitsGetProductVehiclesBySkuResponse['vehicles']>
}) =>
  useQuery({
    queryKey: ['getProductVehiclesBySku', payload.sku, payload.locale],
    queryFn: () =>
      getProductVehiclesBySku({
        sku: payload.sku.value,
        locale: payload.locale.value
      }),
    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * deleteProductVehicleYear - Call | Deletes a product vehicle year.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const deleteProductVehicleYear = async (
  payload: T.vfitsDeleteProductVehicleYearRequest
): Promise<T.vfitsDeleteProductVehicleYearResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/products/${
        payload.product.sku
      }/vehicles/year${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteProductVehicleYearResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * deleteProductVehicleYear - Mutation | A custom hook that returns a mutation function for deleting a product vehicle year from the vFits API.
 * @param payload An optional object containing options to configure the mutation function.
 * @returns A mutation function for deleting a product vehicle year.
 */
const useDeleteProductVehicleYear = (payload?: {
  options?: UseMutationOptions<
    T.vfitsDeleteProductVehicleYearResponse,
    unknown,
    T.vfitsDeleteProductVehicleYearRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteProductVehicleYear,
    ...payload?.options
  })

/**
 * updateProductVehicleYears - Call | Updates the vehicle years for a product.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const updateProductVehicleYears = async (
  payload: T.vfitsUpdateProductVehicleYearsRequest
): Promise<T.vfitsUpdateProductVehicleYearsResponse> =>
  await useWretch('FsyncAPI')
    .put(
      JSON.stringify(payload),
      `${API_PREFIX}/products/${payload.sku}/vehicles?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsUpdateProductVehicleYearsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * updateProductVehicleYears - Mutation | A custom hook that returns a function to update a product's vehicle years using the `useMutation` hook from `react-query`.
 * @param payload An optional object containing options to pass to the `useMutation` hook.
 * @returns A function to update a product's vehicle years.
 */
const useUpdateProductVehicleYears = (payload?: {
  options?: UseMutationOptions<
    T.vfitsUpdateProductVehicleYearsResponse,
    unknown,
    T.vfitsUpdateProductVehicleYearsRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: updateProductVehicleYears,
    ...payload?.options
  })

/**
 * loadVehicleYears - Call | Loads vehicle years based on the provided payload.
 * @param payload - The payload for the request.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const loadVehicleYears = async (
  payload: T.vfitsLoadVehicleYearsRequest
): Promise<T.vfitsLoadVehicleYearsResponse> =>
  await useWretch('FsyncAPI')
    .get(
      `${API_PREFIX}/yearsByVehicle?${qs.stringify({
        ...payload,
        locale: payload?.locale || 'en'
      })}`
    )
    .json<T.vfitsLoadVehicleYearsResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * loadVehicleYears - Query | A custom hook that loads vehicle years based on the provided make, model, and option IDs.
 * @param payload An object containing the makeId, modelId, optionId, locale, and options.
 * @returns A `useQuery` hook that fetches the vehicle years.
 */
const useLoadVehicleYears = (payload: {
  makeId: ComputedRef<number>
  modelId: ComputedRef<number>
  optionId: ComputedRef<number>
  locale?: ComputedRef<string>
  options?: UseQueryOptions<T.vfitsLoadVehicleYearsResponse>
}) =>
  useQuery({
    queryKey: [
      'loadVehicleYears',
      payload.makeId,
      payload.modelId,
      payload.optionId,
      payload?.locale || 'en'
    ],
    queryFn: () =>
      loadVehicleYears({
        make_id: payload.makeId.value,
        model_id: payload.modelId.value,
        option_id: payload.optionId.value,
        locale: payload.locale?.value
      }),

    refetchOnWindowFocus: false,
    ...payload.options
  })

/**
 * addProductVehicle - Call | Adds a vehicle to a product.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response object.
 */
export const addVehicleToProduct = async (
  payload: T.vfitsAddProductVehicleRequest
): Promise<T.vfitsAddProductVehicleResponse> =>
  await useWretch('FsyncAPI')
    .post(
      JSON.stringify(payload),
      `${API_PREFIX}/products/${payload.sku}/vehicles?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsAddProductVehicleResponse>()
    .then((res) => {
      Notify.create({
        message: res.message,
        position: 'bottom',
        icon: 'check',
        color: 'green'
      })

      return res
    })
    .catch((err) => err)

/**
 * addProductVehicle - Mutation | A custom hook that returns a mutation function to add a vehicle to a product in the vFits API.
 * @param payload An optional object containing options to configure the mutation function.
 * @returns A mutation function to add a vehicle to a product.
 */
const useAddVehicleToProduct = (payload?: {
  options?: UseMutationOptions<
    T.vfitsAddProductVehicleResponse,
    unknown,
    T.vfitsAddProductVehicleRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: addVehicleToProduct,
    ...payload?.options
  })

/**
 * deleteProductVehicle - Call | Deletes a product vehicle.
 * @param payload - The request payload.
 * @returns A promise that resolves to the response data.
 */
export const deleteProductVehicle = async (
  payload: T.vfitsDeleteProductVehicleRequest
): Promise<T.vfitsDeleteProductVehicleResponse> =>
  await useWretch('FsyncAPI')
    .body(JSON.stringify(payload))
    .delete(
      `${API_PREFIX}/products/${payload.product.sku}/vehicles?${qs.stringify({
        locale: usePagesStore().language?.value
      })}`
    )
    .json<T.vfitsDeleteProductVehicleResponse>()
    .then((res) => {
      Notify.create({
        message: res.message,
        position: 'bottom',
        icon: 'check',
        color: 'green'
      })

      return res
    })
    .catch((err) => err)

/**
 * deleteProductVehicle - Mutation | A custom hook that returns a mutation function for deleting a product vehicle from the vFits API.
 * @param payload An optional object containing options to configure the mutation function.
 * @returns A mutation function for deleting a product vehicle.
 */
const useDeleteProductVehicle = (payload?: {
  options?: UseMutationOptions<
    T.vfitsDeleteProductVehicleResponse,
    unknown,
    T.vfitsDeleteProductVehicleRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: deleteProductVehicle,
    ...payload?.options
  })

/**
 * updateVehicle - Call | Updates a vehicle in the VFITS API.
 * @param payload The request payload for updating a vehicle.
 * @returns A promise that resolves to the response from the VFITS API.
 */
export const updateVehicle = async (
  payload: T.vfitsUpdateVehicleRequest
): Promise<T.vfitsUpdateVehicleResponse> =>
  await useWretch('FsyncAPI')
    .post(JSON.stringify(payload), `${API_PREFIX}/update_vehicle`)
    .json<T.vfitsUpdateVehicleResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * submit Gearlist - Call | Submits a gearlist to the VFITS API.
 */
export const submitGearlist = async (
  payload: T.vfitsSubmitGearlistRequest
): Promise<T.vfitsSubmitGearlistResponse> =>
  await useWretch('FrAPI')
    .post(
      JSON.stringify(payload),
      '/customer/Customer/region/AUS/GearListMailer'
    )
    .json<T.vfitsSubmitGearlistResponse>()
    .then((res) => res)
    .catch((err) => err)

/**
 * submit Gearlist - Mutation | A custom hook that returns a mutation function for submitting a gearlist to the VFITS API.
 */
const useSubmitGearlist = (payload?: {
  options?: UseMutationOptions<
    T.vfitsSubmitGearlistResponse,
    unknown,
    T.vfitsSubmitGearlistRequest,
    unknown
  >
}) =>
  useMutation({
    mutationFn: submitGearlist,
    ...payload?.options
  })

/**
 * getProducts - Call | Retrieves products from the VFITS API.
 */
export const getProducts = async (): Promise<
  T.vfitsGetProductsResponse['data']
> =>
  await useWretch('FsyncAPI')
    .url(`${API_PREFIX}/products`)
    .get()
    .json<T.vfitsGetProductsResponse>()
    .then(({ data }) => {
      return data.map(function (product) {
        let text = product.description
        if (product.description.match(/-\s*?by\s+.*/i)) {
          text = product.description.replace(/-\s*?by\s+.*/i, ' ')
        }

        return {
          ...product,
          description: text,
          label: text
        }
      })
    })
    .catch((err) => err)

/**
 * getProducts - Query | A custom hook that returns a query object for fetching products from the VFITS API.
 */
const useGetProducts = (payload?: {
  options?: UseQueryOptions<T.vfitsGetProductsResponse['data']>
}) =>
  useQuery({
    queryKey: ['getProducts'],
    queryFn: getProducts,
    refetchOnWindowFocus: false,
    ...payload?.options
  })
/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {
  useGetMakes,
  useGetMakeModels,
  useGetMakeModelOptionYearsProducts,
  useGetProductVehiclesBySku,
  useLoadVehicleYears,
  useGetModelOptions,
  useGetProducts
}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useClearVfitsCache,

  /**
   * Makes
   */
  usePostNewMake,
  usePutMake,
  useDeleteMake,

  /**
   * Models
   */
  usePostNewMakeModel,
  usePutMakeModel,
  useDeleteMakeModel,

  /**
   * Options
   */
  useCreateModelOption,
  useUpdateModelOption,
  useDeleteModelOption,
  /**
   * Other
   */
  useAddProductsToVehicle,
  useDeleteProductFromYear,
  useDeleteProductVehicleYear,
  useUpdateProductVehicleYears,
  useAddVehicleToProduct,
  useDeleteProductVehicle,

  /**
   * Gearlist
   */
  useSubmitGearlist
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const vfitsApiFactory = {
  queries,
  mutations
}
