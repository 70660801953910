/**
 * ================================================
 * Release notes factory API
 * This factory contains all the methods for interacting with the release/pathnotes feature.
 * ================================================
 */

import type { QueryFunctionContext, UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type * as T from './types'

const API_PREFIX = '/api/v2/release-notes'

const useGetReleaseNotes = (payload: { currentPage: ComputedRef<number> }) =>
  useQuery({
    queryKey: ['releaseNotes'],
    queryFn: async (): Promise<T.ReleaseNotesResponse> =>
      useWretch('FsyncAPI')
        .url(`${API_PREFIX}?page=${payload.currentPage.value}`)
        .get()
        .json<T.ReleaseNotesResponse>()
        .then((res) => {
          //res.releaseNotes.data.reverse()
          return res
        })
        .catch((err) => err)
  })

const useReleaseNotesBySystem = (payload: {
  system: ComputedRef<string>
  currentPage: ComputedRef<number>
}) =>
  useQuery({
    queryKey: ['filteredReleaseNotes', payload.system.value],
    queryFn: async (): Promise<T.ReleaseNotesResponse> =>
      useWretch('FsyncAPI')
        .url(
          `${API_PREFIX}?system=${payload.system.value}&page=${payload.currentPage.value}`
        )
        .get()
        .json<T.ReleaseNotesResponse>()
        .then((res) => {
          //res.releaseNotes.data.reverse()

          return res
        })
        .catch((err) => err)
  })

const useGetLatestReleaseNotes = () =>
  useQuery({
    queryKey: ['releaseNotes', 'all'],
    queryFn: async (): Promise<T.LatestReleaseNotes[]> => {
      //FIXME: see if we can get these dynamically
      const repos = [
        'Firestarter',
        'FrontRunner-Campfire',
        'FrontRunner-FSYNC',
        'FrontRunner-PermissionsAPI',
        'Frontrunner.FREDI',
        'Magento2-Frontend'
      ]

      const releaseRequests = repos.map((repo) =>
        useWretch('FsyncAPI')
          .url(`${API_PREFIX}?system=${repo}`)
          .get()
          .json<T.ReleaseNotesResponse>()
          .then((res) => ({
            repo,
            latestRelease: res.releaseNotes.data[0] ?? null
          }))
          .catch(() => ({
            repo,
            latestRelease: null
          }))
      )

      const results = await Promise.allSettled(releaseRequests)

      return results
        .filter(
          (result) =>
            result.status === 'fulfilled' && result.value.latestRelease
        )
        .map(
          (result) =>
            (result as PromiseFulfilledResult<T.LatestReleaseNotes>).value
        )
    }
  })

export const queries = {
  useGetReleaseNotes,
  useReleaseNotesBySystem,
  useGetLatestReleaseNotes
}

export const ReleaseNotesApiFactory = {
  queries
}
